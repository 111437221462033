import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import projectStyles from "./project.module.css"

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      description
      link
      content {
        json
      }
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const fields = node.data.target.fields
      return (
        <div>
          <img
            alt={fields.title["en-US"]}
            src={fields.file["en-US"].url + "?q=70&w=900"}
          />
        </div>
      )
    },
  },
}

export default function Blog({ data }) {
  const projectData = data.contentfulProject
  return (
    <Layout>
      <Head title={data.contentfulProject.title} />
      <div className={`container ${projectStyles.projectContainer}`}>
        <div className="three-quarters">
          <h1 className="page-title">{projectData.title}</h1>
          <main
            className={projectStyles.projectContent}
            style={{ marginBottom: "6rem" }}
          >
            <p>{projectData.description}</p>
            <div
              className={projectStyles.projectVisitButton}
              style={{ margin: "3rem 0", textAlign: "center" }}
            >
              <a
                href={projectData.link}
                className="link-button"
                target="_blank"
                rel="noreferrer"
              >
                Ver proyecto desplegado
              </a>
            </div>
            <h1 style={{ fontSize: "1.8rem" }}>Detalles:</h1>
            {projectData.content &&
              documentToReactComponents(projectData.content.json, options)}
          </main>
          <div
            className={projectStyles.projectVisitButton}
            style={{ textAlign: "center" }}
          >
            <a
              href={projectData.link}
              className="link-button"
              target="_blank"
              rel="noreferrer"
            >
              Ver proyecto desplegado
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
